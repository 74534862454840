import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const Videos = () => {
  useEffect(() => {
    // window.dataLayer = window.dataLayer || [];
    // window.dataLayer.push({
    //     event: "page_view", // или другой тип события
    //     page_url: window.location.href,
    // });

    setTimeout(() => {
      window.location.href = 'https://drive.google.com/drive/folders/15X7f6nPGyzPUqxo-H3QWKM-BxPUrPtZ7';
    }, 500);
  }, []);

  return null;
};

export default Videos;
